@media only screen and (min-width: 750px){
  .shipping--countries-selection-modal {
      display: flex;
  }
  .waio_shipping {
      position: fixed;
      height: calc(100% - 14rem);
      min-width: 35%;
  }
  .waio_shipping.right_ {
      max-width: 37%;
      right: 25px;
  }
  .waio_shipping.left_ {
      max-width: 44%;
  }
  
  }
  .Polaris-Page.Polaris-Page--newDesignLanguage {
      overflow-y: hidden;
  }
  .waio_shipping.left_ .waio_shipping_list_main {
  height: 325px;
  }
  .waio_shipping.right_ .waio_shipping_list_main {
      height: 340px;
  }
  
  .waio_shipping.left_ .waio_shipping_list_main , .waio_shipping.right_ .waio_shipping_list_main {
      overflow-y: auto;
      border-bottom: 1px solid #ddd;
  }
  .waio_shipping.left_ , .waio_shipping.right_  {
      border: 1px solid #dddd;
      border-radius: 8px;
      height: auto;
  }
  
  .waio_shipping.right_ .country_name, .waio_shipping.left_ .country_name {
      padding: 10px !important;
      padding-left: 20px !important;
      border-top: 1px solid #ddd;
  }
  
  .search_shipping {
      padding: 1.2rem 2rem;
      border-bottom: 1px solid #ddd;
  }
  
  .lbl_resourse label.Polaris-Choice.Polaris-Choice--labelHidden {
      padding-top: 5px;
  }
  .list_provinces {
      border-top: 0.1rem solid #dfe3e8;
  }